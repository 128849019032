import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Field, reduxForm, getFormValues} from 'redux-form';
import toast, {Toaster} from 'react-hot-toast';
import _ from 'lodash';
import LocaleStrings from '@language';
import {BaseComponent, LABEL_POSITION_TOP} from '@baseComponent';
import {classNames} from '@commonFunction';
import {
  updateTellusmoreAppuser,
  sendVerificationemail,
  sendInvoice,
} from '@mainActions';

class Tellusmore extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      btnLoader: false,
      isBtnDisable: false,
      show: false,
      show1: false,
      isTncClicked: false,
      phone: '',
      country: '',
    };
    // All Bind Functions
  }

  componentDidMount() {
    // if (this.props.isLoggedIn === true) {
    //   // this.props.history.push('/dashboard');
    // } else {
    //   this.props.history.push("/login");
    // }
  }

  UNSAFE_componentWillMount() {
    this.setState({
      loading: false,
      btnLoader: false,
      isBtnDisable: false,
      show: false,
      show1: false,
    });
  }

  componentWillUnmount() {
    this.setState({
      loading: false,
      btnLoader: false,
      isBtnDisable: false,
      show: false,
      show1: false,
    });
  }

  /* FORM SUBMIT */
  onFormSubmit(values) {
    let {session, user} = this.props;
    // console.log("user", user);
    values.appuserid = user.appuserid;
    values.name = user.name;
    values.clientid = user.clientid;
    values.role = 'client';

    if (values && values.companyname) {
      values.companyname = _.trim(values.companyname);
    }
    if (values && values.department && values.department) {
      values.department = _.trim(values.department);
    }

    if (values.phonenumber && _.trim(values.phonenumber.length > 4)) {
      values.phonenumber = values.phonenumber.replace(/[- )( +]/g, '');
    } else {
      toast.error('Please Enter Valid Phone Number');
      return false;
    }

    let emailobj = {};
    emailobj.email = user.email;

    // this.props.history.push('/dashboard/main/dashboardmain');
    if (
      values &&
      values.companyname !== '' &&
      values.phonenumber !== '' &&
      values.country !== '' &&
      values.salespeoplecount !== '' &&
      values.requiredleadcount !== '' &&
      values.department !== ''
    ) {
      this.setState({isBtnDisable: true});
      this.setState({btnLoader: true});
      this.props.updateTellusmoreAppuser(session, values, response => {
        if (response.success === 1) {
          toast.success(LocaleStrings.mainsignup2_success_message);
          this.setState({isBtnDisable: false});
          this.setState({btnLoader: false});
          this.props.sendInvoice(
            session,
            {clientid: values.clientid},
            respo => {
              console.log('Invoice Sent on Update => ', respo);
            },
          );
          if (user.isverified == 1 || user.isverified == '1') {
            this.props.history.push('/dashboard/main/dashboardmain');
          } else {
            this.props.history.push('/verifyemail');
            this.props.sendVerificationemail(session, emailobj);
          }

          this.props.reset();
        } else {
          // If Un successfull Registration
          toast.error(LocaleStrings.something_went_wrong);
          this.setState({isBtnDisable: false});
          this.setState({btnLoader: false});
          this.props.reset();
          // return false;
        }
      });
    } else {
      toast.error(LocaleStrings.common_error_fillallrequiredfields);
    }
  }

  componentWillReceiveProps(nextProps) {}

  _phoneChange = (value, country, e, formattedValue) => {
    this.setState({phone: value, country: country.dialCode});
  };

  _handleSkip = () => {
    this.props.history.push('/verifyemail');
    // this.props.history.push('/dashboard/main/dashboardmain');
    let {session, user} = this.props;
    this.props.sendInvoice(session, {clientid: user.clientid}, respo => {
      console.log('Invoice Sent on Skip => ', respo);
    });
  };

  render() {
    var {handleSubmit, btnLoading, language} = this.props;
    let countryOpts = [];

    _.forEach(this.props.allcountryList, function (value) {
      var obj = {
        name: value.name,
        value: value.name,
      };

      countryOpts.push(obj);
    });

    return (
      <>
        <form
          className="space-y-2  xs:px-20 lg:px-0"
          onSubmit={handleSubmit(this.onFormSubmit.bind(this))}>
          <div className="grid xs:grid-cols-1 lg:grid-cols-3 xs:mt-10 lg:mt-28">
            <div></div>
            <div className="lg:w-5/6  lg:ml-auto overflow-y-auto">
              <div className="text-4xl font-bold">
                {LocaleStrings.mainsignup_page2_title}
              </div>
              <div className="mt-4 text-gray500 text-base">
                {LocaleStrings.mainsignup_page2_subtitle}
              </div>
              <div className="mt-4">
                <Field
                  name="companyname"
                  label={LocaleStrings.settings_myaacounts_field_label_company}
                  placeholder={
                    LocaleStrings.settings_myaacounts_field_placeholder_company
                  }
                  type="text"
                  mandatory="true"
                  component={this.renderFieldText}
                  labelposition={LABEL_POSITION_TOP}
                  classNameLabel="text-sm font-normal text-secondary"
                />
              </div>
              <div className="mt-4">
                <Field
                  name="phonenumber"
                  label={LocaleStrings.settings_myaacounts_field_phone}
                  type="text"
                  mandatory="true"
                  component={this.renderPhonewithFlag}
                  className="cust-input-field"
                  classNameLabel="text-box-lable"
                  phone={this.state.phone}
                  phoneChange={this._phoneChange}
                />
              </div>
              <div className="mt-4">
                <Field
                  name="country"
                  label={LocaleStrings.settings_myaacounts_field_country}
                  placeholder={'Select Country'}
                  mandatory="true"
                  component={this.renderSelect}
                  labelposition={LABEL_POSITION_TOP}
                  opts={countryOpts}
                  className="mt-1 block w-full pl-3 pr-10 py-2 border border-gray-300 text-base border-ternary focus:outline-none focus:ring-primary focus:border-primary sm:text-sm rounded-md"
                  classNameLabel="block text-box-lable"
                />
              </div>
              <div className="mt-4">
                <Field
                  name="salespeoplecount"
                  label={
                    LocaleStrings.settings_myaacounts_field_label_salespeoplecount
                  }
                  placeholder={
                    LocaleStrings.settings_myaacounts_field_label_salespeoplecount
                  }
                  type="number"
                  mandatory="true"
                  min="0"
                  component={this.renderFieldText}
                  labelposition={LABEL_POSITION_TOP}
                  classNameLabel="text-sm font-normal text-secondary"
                />
              </div>
              <div className="mt-4">
                <Field
                  name="requiredleadcount"
                  label={
                    LocaleStrings.settings_myaacounts_field_label_leadcountrequired
                  }
                  placeholder={
                    LocaleStrings.settings_myaacounts_field_label_leadcountrequired
                  }
                  type="number"
                  min="0"
                  component={this.renderFieldText}
                  labelposition={LABEL_POSITION_TOP}
                  classNameLabel="text-sm font-normal text-secondary"
                />
              </div>
              <div className="mt-4">
                <Field
                  name="department"
                  label={
                    LocaleStrings.settings_myaacounts_field_label_department
                  }
                  placeholder={
                    LocaleStrings.settings_myaacounts_field_label_department
                  }
                  type="text"
                  component={this.renderFieldText}
                  labelposition={LABEL_POSITION_TOP}
                  classNameLabel="text-sm font-normal text-secondary"
                />
              </div>

              <div className="pt-6 flex space-x-4">
                {/* <button
                                    type="button"
                                    className="btn-white"
                                    onClick={(e) => this._handleSkip(e)}
                                >
                                    {LocaleStrings.mainsignup_skip}
                                </button> */}
                <button
                  type="submit"
                  disabled={this.state.isBtnDisable}
                  className={classNames(
                    this.state.isBtnDisable ? 'cursor-not-allowed' : '',
                    'btn-primary mb-10',
                  )}>
                  <svg
                    className={classNames(
                      this.state.btnLoader ? '' : 'sr-only',
                      'animate-spin-medium h-5 w-5 rounded-full mx-2',
                    )}
                    viewBox="0 0 1024 1024"
                    focusable="false"
                    data-icon="loading"
                    width="1em"
                    height="1em"
                    fill="currentColor"
                    aria-hidden="true">
                    <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                  </svg>
                  {LocaleStrings.mainsignup2_updateacc}
                </button>
              </div>
            </div>
          </div>
        </form>
        <Toaster />
      </>
    );
  }
}

function validate(values) {
  var errors = {};
  var requiredFields = [];

  requiredFields = [
    'companyname',
    'phonenumber',
    'country',
    'salespeoplecount',
  ];

  requiredFields.forEach(field => {
    if (!values[field] || values[field] === '') {
      errors[field] = LocaleStrings.required;
    }
  });

  return errors;
}

var mapStateToProps = state => ({
  isLoggedIn: state.isLoggedIn,
  user: state.user,
  session: state.session,
  language: state.language,
  allcountryList: state.allcountryList,
  formValues: getFormValues('tellusmoreForm')(state),
});

export default reduxForm({
  validate,
  form: 'tellusmoreForm',
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(
  connect(mapStateToProps, {
    updateTellusmoreAppuser,
    sendVerificationemail,
    sendInvoice,
  })(Tellusmore),
);
