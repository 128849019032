import moment from 'moment';
var passwordValidator = require('password-validator');
var schema = new passwordValidator();

export const ENV = process.env.REACT_APP_ENV;

// ========= Dev ============ //

export var INSTANCE_URL =
  ENV === 'dev' ? 'https://dev15.kipplo.com' : 'https://api.kipplo.com';

export var MAIN_URL = `${INSTANCE_URL}/`;

// Stripe TEST
/*export var STRIPE_PUBLISHABLE_KEY =
  'pk_test_51JZh3YLTE0Q0BfEhBVDsWaaGbsGRhr7pePYbfRwtUOdQ4azVzFjbqV5XaUUFUdD5Ku8jrTRGzT5Y4nekFMDfk2I1005zaEhgp5'; // Test*/

// Stripe LIVE
export var STRIPE_PUBLISHABLE_KEY =
  ENV === 'dev'
    ? 'pk_test_51OZvGGGQb4dD9z8oW4sD6QVNQREgADZQ5Vc7EyfkvsRsikFYo9rCEA78z6fjvA6FKOidGOCbEEPm0QcBzkW9x3YC00D33ywjZ2'
    : 'pk_live_51OZvGGGQb4dD9z8oQ1fZc8JjxBFwJ8L0LQIXKX6X0QZaZFKmJYCYGe3a98vVNGNQbHPogWLIAXHGm6LrLITdKQY100vc6hbqSo'; // LIVE

export var BASE_IMAGES_URL = `${INSTANCE_URL}/api/v2/files`;

export var APP_API_KEY =
  'b1a8fffca04edbc42c088a0fbdb453ccabf387c0727400d9d2153a9bc403920a';
export var UPLOAD_FILES_API_KEY = APP_API_KEY; //'7ee3543d9a4d75a86ff4af6fc614fa989693bf35f5fa8dfe519c0f56c86231ce';

// CURRENCY I
export var CURRENCY_RATE = 1;
export var CURRENCY = '$';

export var TRIALPLANID = 1755155169497910;
export var PROFESSIONAL_YEARLY = 1755155169497914;
export var BASIC_MONTHLY = 1755155169497911;

export var ENTERPRISE_MONTHLY = 1755155169497915;
export var ENTERPRISE_YEARLY = 1755155169497916;

export var EXTENSION_LINK =
  'https://chrome.google.com/webstore/detail/kipplo/mfilcfngbefbaeggcglepgdklgdffeih';

export var CURRENCY_RATE_USD = 1;
export var CURRENCY_RATE_QR = 3.65;
export var CURRENCY_USD = '$';
export var CURRENCY_QR = 'QAR';

// SOCIAL LOGIN
export var FACEBOOKID = '902050527004908';
export var GOOGLEID =
  '519924169573-cg5av868mkp1cje3cbhbapi446gqe7rv.apps.googleusercontent.com';
export var CONSUMER_KEY = 'nFvgJ8XPaZoIc19WxNyjIaUnB';
export var CONSUMER_SECRET =
  'z5bB5zJy387ZK7AmFYRpfYowJHwDBNurclLZIVjXDztNeK9gVn';
export var TWITTER_CALLBACK_URL = 'nFvgJ8XPaZoIc19WxNyjIaUnB://';
export var SOCIALPWD = '&@c9[x~#7>TTUv';

export var LOGIN = 'LOGIN';
export var REGISTER = 'REGISTER';
export var SELECT_APP_ICON = 'SELECTAPPICON';
export var UPDATE_UNSAVED_STATUS = 'UPDATE_UNSAVED_STATUS';
export var SELECT_FEATURE = 'select_feature';
export var SELECTED_CONTENT_FEATURE = 'SELECTED_CONTENT_FEATURE';
export var CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export var EDIT_CONTENT_ICON_NAME = 'EDIT_CONTENT_ICON_NAME';
export var CONTENT_TABS = 'CONTENT_TABS';
export var UPDATE_FILES_UPLOAD_PROGRESS = 'UPDATE_FILES_UPLOAD_PROGRESS';
export var FILES_UPLOAD_FAILED = 'FILES_UPLOAD_FAILED';
export var FILES_UPLOADED = 'FILES_UPLOADED';
export var CONTENT_FILE_TYPE_EVENTGUIDE = 'eventguidefile';
export var CONTENT_FILE_TYPE_SPEAKER = 'speakerfile';
export var CONTENT_FILE_TYPE_SPONSOR = 'sponsorfile';
export var CONTENT_FILE_TYPE_EXHIBITOR = 'exhibitorfile';
export var TO_CHANGE_EMAIL = 'TO_CHANGE_EMAIL';
export var COMMON_FAIL_MESSAGE = 'Something went wrong!!';

export const STRIPE_FEE = 2.9;
export const STRIPE_FEE_EXTRA = 0.3;
export var MAX_INTEREST_COUNT = 20;
export var DATE_FORMAT = 'YYYY-MM-DD';
export var DATE_FORMAT_SM = 'yyyy-MM-dd';
export var DATE_FORMAT_WITH_TIME = 'YYYY-MM-DD HH:mm';
export var DATE_FORMAT_WITH_TIME_WITH_SECOND = 'YYYY-MM-DD HH:mm:ss';
export var DATE_FORMAT_TO_SHOW = 'D MMM YYYY hh:mm A';
export var DATE_FORMAT_TO_SHOW_SHORT = 'D MMM YYYY';
export var MIN_DATE_FORMAT = 'YYYY,MM,DD';
export const isMobile = window.innerWidth <= 500;

export var HIGHEND_PASSWORD = 'uB9*#R!z';
export const GarbageKey = 'A8d36@hfD92W7!';

export var MAX_WIDTH_OR_HEIGHT_VIDEO = 770;
export var MAX_WIDTH_OR_HEIGHT_IMAGE = 770;
export var MAX_VIDEO_DURATION_IN_SEC = 300;
export var MAX_IMAGE_SIZE_IN_BYTES = 2000000;

export var itemCount = calculateItemCount();
export var DISPLAY_DATE_FORMAT = getLocaleShortDateString();
export var DISPLAY_LOCAL_DATE_FORMAT = localeDateFormat();
export const isTablet = determineIfTablet();

export var BASE_STRING =
  '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789ABCDEFGHIJKLMN56789ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456';

// export var PERSONAL_USER_REDIRECT_TO = "/dashboard/profilesettings";
// export var OTHERS_USER_REDIRECT_TO = "/dashboard/salesmanagement";
// export var FREEZE_USER_REDIRECT_TO = "/dashboard/profilesettings";
export var PUBLIC_FILE_PATH = '../../images/common/';

export function timeDiffCalc(dateFuture, dateNow) {
  let diffInMilliSeconds = Math.abs(dateFuture - dateNow) / 1000;

  // calculate days
  const days = Math.floor(diffInMilliSeconds / 86400);
  diffInMilliSeconds -= days * 86400;

  // calculate hours
  const hours = Math.floor(diffInMilliSeconds / 3600) % 24;
  diffInMilliSeconds -= hours * 3600;

  // calculate minutes
  const minutes = Math.floor(diffInMilliSeconds / 60) % 60;
  diffInMilliSeconds -= minutes * 60;

  let difference = '';
  if (days > 0) {
    difference += `${days}D `;
  }

  difference += `${hours}h `;

  difference += `${minutes}m`;

  return difference;
}

function determineIfTablet() {
  var width = window.innerWidth;
  var height = window.innerHeight;

  var istablet = false;

  if (
    (width === 1366 && height === 1024) ||
    (height === 1365 && width === 1024)
  ) {
    //Check for iPad Pro
    istablet = true;
  } else if (
    (width === 1024 && height === 768) ||
    (height === 768 && width === 1024)
  ) {
    //Check for iPad
    istablet = true;
  }
  return istablet;
}

function calculateItemCount() {
  //dynamically calcualting number of items to show as per height of the screen
  var height = window.innerHeight - 300; //250 is calculated to deduct extra space used other than just table
  var maxrowheight = 50;

  var count = Math.ceil(height / maxrowheight);

  return count < 1 ? 1 : count;
}

function getLocaleShortDateString() {
  // var d = new Date(1992, 0, 7);
  var f = {
    'ar-SA': 'dd/MM/yy',
    'bg-BG': 'dd.M.yyyy',
    'ca-ES': 'dd/MM/yyyy',
    'zh-TW': 'yyyy/M/d',
    'cs-CZ': 'd.M.yyyy',
    'da-DK': 'dd-MM-yyyy',
    'de-DE': 'dd.MM.yyyy',
    'el-GR': 'd/M/yyyy',
    'en-US': 'M/d/yyyy',
    'fi-FI': 'd.M.yyyy',
    'fr-FR': 'dd/MM/yyyy',
    'he-IL': 'dd/MM/yyyy',
    'hu-HU': 'yyyy. MM. dd.',
    'is-IS': 'd.M.yyyy',
    'it-IT': 'dd/MM/yyyy',
    'ja-JP': 'yyyy/MM/dd',
    'ko-KR': 'yyyy-MM-dd',
    'nl-NL': 'd-M-yyyy',
    'nb-NO': 'dd.MM.yyyy',
    'pl-PL': 'yyyy-MM-dd',
    'pt-BR': 'd/M/yyyy',
    'ro-RO': 'dd.MM.yyyy',
    'ru-RU': 'dd.MM.yyyy',
    'hr-HR': 'd.M.yyyy',
    'sk-SK': 'd. M. yyyy',
    'sq-AL': 'yyyy-MM-dd',
    'sv-SE': 'yyyy-MM-dd',
    'th-TH': 'd/M/yyyy',
    'tr-TR': 'dd.MM.yyyy',
    'ur-PK': 'dd/MM/yyyy',
    'id-ID': 'dd/MM/yyyy',
    'uk-UA': 'dd.MM.yyyy',
    'be-BY': 'dd.MM.yyyy',
    'sl-SI': 'd.M.yyyy',
    'et-EE': 'd.MM.yyyy',
    'lv-LV': 'yyyy.MM.dd.',
    'lt-LT': 'yyyy.MM.dd',
    'fa-IR': 'MM/dd/yyyy',
    'vi-VN': 'dd/MM/yyyy',
    'hy-AM': 'dd.MM.yyyy',
    'az-Latn-AZ': 'dd.MM.yyyy',
    'eu-ES': 'yyyy/MM/dd',
    'mk-MK': 'dd.MM.yyyy',
    'af-ZA': 'yyyy/MM/dd',
    'ka-GE': 'dd.MM.yyyy',
    'fo-FO': 'dd-MM-yyyy',
    'hi-IN': 'dd-MM-yyyy',
    'ms-MY': 'dd/MM/yyyy',
    'kk-KZ': 'dd.MM.yyyy',
    'ky-KG': 'dd.MM.yy',
    'sw-KE': 'M/d/yyyy',
    'uz-Latn-UZ': 'dd/MM yyyy',
    'tt-RU': 'dd.MM.yyyy',
    'pa-IN': 'dd-MM-yy',
    'gu-IN': 'dd-MM-yy',
    'ta-IN': 'dd-MM-yyyy',
    'te-IN': 'dd-MM-yy',
    'kn-IN': 'dd-MM-yy',
    'mr-IN': 'dd-MM-yyyy',
    'sa-IN': 'dd-MM-yyyy',
    'mn-MN': 'yy.MM.dd',
    'gl-ES': 'dd/MM/yy',
    'kok-IN': 'dd-MM-yyyy',
    'syr-SY': 'dd/MM/yyyy',
    'dv-MV': 'dd/MM/yy',
    'ar-IQ': 'dd/MM/yyyy',
    'zh-CN': 'yyyy/M/d',
    'de-CH': 'dd.MM.yyyy',
    'en-GB': 'dd/MM/yyyy',
    'es-MX': 'dd/MM/yyyy',
    'fr-BE': 'd/MM/yyyy',
    'it-CH': 'dd.MM.yyyy',
    'nl-BE': 'd/MM/yyyy',
    'nn-NO': 'dd.MM.yyyy',
    'pt-PT': 'dd-MM-yyyy',
    'sr-Latn-CS': 'd.M.yyyy',
    'sv-FI': 'd.M.yyyy',
    'az-Cyrl-AZ': 'dd.MM.yyyy',
    'ms-BN': 'dd/MM/yyyy',
    'uz-Cyrl-UZ': 'dd.MM.yyyy',
    'ar-EG': 'dd/MM/yyyy',
    'zh-HK': 'd/M/yyyy',
    'de-AT': 'dd.MM.yyyy',
    'en-AU': 'd/MM/yyyy',
    'es-ES': 'dd/MM/yyyy',
    'fr-CA': 'yyyy-MM-dd',
    'sr-Cyrl-CS': 'd.M.yyyy',
    'ar-LY': 'dd/MM/yyyy',
    'zh-SG': 'd/M/yyyy',
    'de-LU': 'dd.MM.yyyy',
    'en-CA': 'dd/MM/yyyy',
    'es-GT': 'dd/MM/yyyy',
    'fr-CH': 'dd.MM.yyyy',
    'ar-DZ': 'dd-MM-yyyy',
    'zh-MO': 'd/M/yyyy',
    'de-LI': 'dd.MM.yyyy',
    'en-NZ': 'd/MM/yyyy',
    'es-CR': 'dd/MM/yyyy',
    'fr-LU': 'dd/MM/yyyy',
    'ar-MA': 'dd-MM-yyyy',
    'en-IE': 'dd/MM/yyyy',
    'es-PA': 'MM/dd/yyyy',
    'fr-MC': 'dd/MM/yyyy',
    'ar-TN': 'dd-MM-yyyy',
    'en-ZA': 'yyyy/MM/dd',
    'es-DO': 'dd/MM/yyyy',
    'ar-OM': 'dd/MM/yyyy',
    'en-JM': 'dd/MM/yyyy',
    'es-VE': 'dd/MM/yyyy',
    'ar-YE': 'dd/MM/yyyy',
    'en-029': 'MM/dd/yyyy',
    'es-CO': 'dd/MM/yyyy',
    'ar-SY': 'dd/MM/yyyy',
    'en-BZ': 'dd/MM/yyyy',
    'es-PE': 'dd/MM/yyyy',
    'ar-JO': 'dd/MM/yyyy',
    'en-TT': 'dd/MM/yyyy',
    'es-AR': 'dd/MM/yyyy',
    'ar-LB': 'dd/MM/yyyy',
    'en-ZW': 'M/d/yyyy',
    'es-EC': 'dd/MM/yyyy',
    'ar-KW': 'dd/MM/yyyy',
    'en-PH': 'M/d/yyyy',
    'es-CL': 'dd-MM-yyyy',
    'ar-AE': 'dd/MM/yyyy',
    'es-UY': 'dd/MM/yyyy',
    'ar-BH': 'dd/MM/yyyy',
    'es-PY': 'dd/MM/yyyy',
    'ar-QA': 'dd/MM/yyyy',
    'es-BO': 'dd/MM/yyyy',
    'es-SV': 'dd/MM/yyyy',
    'es-HN': 'dd/MM/yyyy',
    'es-NI': 'dd/MM/yyyy',
    'es-PR': 'dd/MM/yyyy',
    'am-ET': 'd/M/yyyy',
    'tzm-Latn-DZ': 'dd-MM-yyyy',
    'iu-Latn-CA': 'd/MM/yyyy',
    'sma-NO': 'dd.MM.yyyy',
    'mn-Mong-CN': 'yyyy/M/d',
    'gd-GB': 'dd/MM/yyyy',
    'en-MY': 'd/M/yyyy',
    'prs-AF': 'dd/MM/yy',
    'bn-BD': 'dd-MM-yy',
    'wo-SN': 'dd/MM/yyyy',
    'rw-RW': 'M/d/yyyy',
    'qut-GT': 'dd/MM/yyyy',
    'sah-RU': 'MM.dd.yyyy',
    'gsw-FR': 'dd/MM/yyyy',
    'co-FR': 'dd/MM/yyyy',
    'oc-FR': 'dd/MM/yyyy',
    'mi-NZ': 'dd/MM/yyyy',
    'ga-IE': 'dd/MM/yyyy',
    'se-SE': 'yyyy-MM-dd',
    'br-FR': 'dd/MM/yyyy',
    'smn-FI': 'd.M.yyyy',
    'moh-CA': 'M/d/yyyy',
    'arn-CL': 'dd-MM-yyyy',
    'ii-CN': 'yyyy/M/d',
    'dsb-DE': 'd. M. yyyy',
    'ig-NG': 'd/M/yyyy',
    'kl-GL': 'dd-MM-yyyy',
    'lb-LU': 'dd/MM/yyyy',
    'ba-RU': 'dd.MM.yy',
    'nso-ZA': 'yyyy/MM/dd',
    'quz-BO': 'dd/MM/yyyy',
    'yo-NG': 'd/M/yyyy',
    'ha-Latn-NG': 'd/M/yyyy',
    'fil-PH': 'M/d/yyyy',
    'ps-AF': 'dd/MM/yy',
    'fy-NL': 'd-M-yyyy',
    'ne-NP': 'M/d/yyyy',
    'se-NO': 'dd.MM.yyyy',
    'iu-Cans-CA': 'd/M/yyyy',
    'sr-Latn-RS': 'd.M.yyyy',
    'si-LK': 'yyyy-MM-dd',
    'sr-Cyrl-RS': 'd.M.yyyy',
    'lo-LA': 'dd/MM/yyyy',
    'km-KH': 'yyyy-MM-dd',
    'cy-GB': 'dd/MM/yyyy',
    'bo-CN': 'yyyy/M/d',
    'sms-FI': 'd.M.yyyy',
    'as-IN': 'dd-MM-yyyy',
    'ml-IN': 'dd-MM-yy',
    'en-IN': 'dd-MM-yyyy',
    'or-IN': 'dd-MM-yy',
    'bn-IN': 'dd-MM-yy',
    'tk-TM': 'dd.MM.yy',
    'bs-Latn-BA': 'd.M.yyyy',
    'mt-MT': 'dd/MM/yyyy',
    'sr-Cyrl-ME': 'd.M.yyyy',
    'se-FI': 'd.M.yyyy',
    'zu-ZA': 'yyyy/MM/dd',
    'xh-ZA': 'yyyy/MM/dd',
    'tn-ZA': 'yyyy/MM/dd',
    'hsb-DE': 'd. M. yyyy',
    'bs-Cyrl-BA': 'd.M.yyyy',
    'tg-Cyrl-TJ': 'dd.MM.yy',
    'sr-Latn-BA': 'd.M.yyyy',
    'smj-NO': 'dd.MM.yyyy',
    'rm-CH': 'dd/MM/yyyy',
    'smj-SE': 'yyyy-MM-dd',
    'quz-EC': 'dd/MM/yyyy',
    'quz-PE': 'dd/MM/yyyy',
    'hr-BA': 'd.M.yyyy.',
    'sr-Latn-ME': 'd.M.yyyy',
    'sma-SE': 'yyyy-MM-dd',
    'en-SG': 'd/M/yyyy',
    'ug-CN': 'yyyy-M-d',
    'sr-Cyrl-BA': 'd.M.yyyy',
    'es-US': 'M/d/yyyy',
  };

  var l = navigator.language ? navigator.language : navigator['userLanguage'],
    // y = d.getFullYear(),
    // m = d.getMonth() + 1,
    // d = d.getDate();
    f = l in f ? f[l] : 'MM/dd/yyyy';
  return f.toUpperCase();
}

function localeDateFormat() {
  var localeData = moment.localeData();
  var userDateFormat = localeData.longDateFormat('L');
  //To be determined properly.
  userDateFormat = 'DD-MM-YYYY HH:mm';
  return userDateFormat;
}

export function converDateIntoLocal(date) {
  var stillUtc = moment.utc(date).toDate();
  var local = moment(stillUtc).local().format('DD-MM-YYYY HH:mm');
  return local;
}

// Get Device height (calculate)
export var contentHeight = calculateHeight();

function calculateHeight() {
  if (window.innerHeight < 665) {
    return window.innerHeight + 60;
  } else {
    return 0;
  }
}

export function validatePercent(value) {
  var re = /(^100(\.0{1,2})?$)|(^([1-9]([0-9])?|0)(\.[0-9]{1,2})?$)/;
  return re.test(value);
}

export function validateEmail(email) {
  var re = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,10})+$/;
  return re.test(email);
}

export function validatepassword(password) {
  schema
    .is()
    .min(8) // Minimum length 8
    .is()
    .max(100) // Maximum length 100
    .has()
    .uppercase() // Must have uppercase letters
    .has()
    .lowercase() // Must have lowercase letters
    .has()
    .digits(1) // Must have at least 2 digits
    .has()
    .not()
    .spaces();

  let passwordValidate = schema.validate(password);
  return passwordValidate;
}

export function validateUrl(url) {
  var re =
    /^(?:(?:(?:https?|http):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/;
  return re.test(url);
}

export function validateOnlyNums(value) {
  var re = /^\d+$/;
  var check = re.test(value);

  return check;
}

export function validatePhoneNumbers(value) {
  let re =
    /^[\+]?[(]?[0-9]{2,5}[)]?[-\s\.]?[0-9]{2,5}[-\s\.]?[0-9]{2,5}[-\s\.]?[0-9]{4,6}$/im;
  // let re = /^\+(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\W*\d\W*\d\W*\d\W*\d\W*\d\W*\d\W*\d\W*\d\W*(\d{1,2})$/
  return re.test(value);
}

export function validateWhitespace(value) {
  return value === null || value.match(/^ *$/) !== null;
}

export function validateSpecialChar(string) {
  var re = /[~`!@#$%\^&*()+=\-\[\]\\';,/{}|\\":<>\?]/g;
  return re.test(string);
}

export var dashboardContentWidth = calculatedDashboardContentWidth();

function calculatedDashboardContentWidth() {
  return window.innerWidth - (isTablet ? 0 : 320);
}

// Calculate Device width
export var chartWidth = calculatedChartWidth();

function calculatedChartWidth() {
  return window.innerWidth - 200;
}

export var usersChartWidth = calculatedUsersChartWidth();

function calculatedUsersChartWidth() {
  return window.innerWidth - 400;
}

export var fullDashboardContentWidth = calculatedFullDashboardContentWidth();

function calculatedFullDashboardContentWidth() {
  return window.innerWidth - 5;
}

export var contentContainerWidth = calculatedContentContainerWidth();

function calculatedContentContainerWidth() {
  // return (window.innerWidth-(isTablet?0:320)-275);
  return window.innerWidth - 324;
}

export var ticketsContentContainerWidth =
  calculatedTicketsContentContainerWidth();

function calculatedTicketsContentContainerWidth() {
  return window.innerWidth - 324;
}

export var commonContainerWidth = calculatedCommonContainerWidth();

function calculatedCommonContainerWidth() {
  return window.innerWidth - 325;
}

export var chooserPopupHeight = calculatedChooserPopupHeight();

function calculatedChooserPopupHeight() {
  return window.innerHeight - 150;
}

export var chooserPopupGridHeight = calculatedChooserPopupGridHeight();

function calculatedChooserPopupGridHeight() {
  return window.innerHeight - 170;
}

export var courseContainerWidth = calculatedCourseContainerWidth();
function calculatedCourseContainerWidth() {
  return window.innerWidth - 325;
}
