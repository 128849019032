import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Fragment} from 'react';
import {confirmAlert} from 'react-confirm-alert';
import _ from 'lodash';
import LocaleStrings from '@language';

import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import {PROFESSIONAL_YEARLY, EXTENSION_LINK} from '@constant';
import {Switch, Route} from 'react-router-dom';
import {Menu, Transition} from '@headlessui/react';
// Import Action
import {
  showNotificatonCount,
  isNotificationModalOpen,
  onSidebarMenuSelected,
} from '@sidebarActions';

// Import pages
import Sidebar from '@sidebar';
import UpgradePlan from '@upgradePlan';
import PayFail from '@payfail';
import PlanSuccess from '@paysuccess';
import CreditAddons from '@creditAddon';
import toast, {Toaster} from 'react-hot-toast';
import Settings from '@mainSettings';
import {
  logout,
  fetchsessionData,
  resetStore,
  getDeviceSize,
} from '@mainActions';
import {onsettingsSidebarMenuSelected} from '@mainSettingsActions';
import ScheduleDemo from '@scheduledemo';
import PrivacyPolicy from '@privacypolicy';
import Termsofuse from '@termsofuse';
import ChromePrivacy from '@chromeprivacy';
import {fetchAppuser} from '@sidebarActions';
// Import style/css
import '../sidebar.css';

class Main extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sidebarOpen: false,
      selectMenuNum: 1,
      textDiretion: this.props.language === 'ar' ? 'rtl' : '', // not using current, kept incase for language change
      selectedThreadId: '',
      toberemoved: false,
    };

    // All Binded Functions

    this.onLogoutClicked = this.onLogoutClicked.bind(this);
  }

  componentDidMount() {
    this.props.getDeviceSize();
    let {session} = this.props;
    // From extension the platform is called so below code is for verifying the session and make the user able to enter platform
    var url_string = window.location.href;
    var historypush = url_string.split('?')[0];
    var query = url_string.split('?')[1];
    var historypushLink = historypush.split('#')[1];

    var params = {};

    if (query) {
      var vars = query.split('&');
      for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split('=');
        params[pair[0]] = decodeURIComponent(pair[1]);
      }
      this.setState({params: params});

      if (params.session_token) {
        this.props.fetchsessionData(params, this.props.session, response => {
          if (response.success === 0) {
            toast.error(LocaleStrings.invalid_email_phone_password);
            this.props.history.push('/login');
            this.props.resetStore();
          } else {
            // toast.success(LocaleStrings.login_success);
            this.props.history.push(historypushLink);
          }
        });
      } else {
        // check added new
        if (this.props.isLoggedIn !== true) {
          this.props.history.push('/login');
        }
      }
    } else {
      if (this.props.isLoggedIn === true) {
        //  Checking all the conditions below after login (DELETED USER OR UNVERIFIED, redirecting accordingly)
        if (this.props.user.appuserid) {
          this.props.fetchAppuser(
            session,
            this.props.user.appuserid,
            callback => {
              if (
                callback &&
                callback.success == 1 &&
                callback.data &&
                callback.data.resource &&
                callback.data.resource.length > 0
              ) {
                if (
                  callback.data.resource[0].isdeleted == 1 ||
                  callback.data.resource[0].isdeleted == '1'
                ) {
                  toast.error(LocaleStrings.mainlogin_user_isdeleted);
                  clearInterval(this.interVal);
                  this.props.logout(this.props.session);
                } else if (
                  callback.data.resource[0].isverified == '0' ||
                  callback.data.resource[0].isverified == 0
                ) {
                  toast.error(`Unverfied User`);
                  // this.props.history.push("/verifyemail");
                  clearInterval(this.interVal);
                  this.props.logout(this.props.session);
                } else if (
                  callback.data.resource[0].isactive == '0' ||
                  callback.data.resource[0].isverified == 0
                ) {
                  toast.error(LocaleStrings.disabled_by_admin_error);
                  clearInterval(this.interVal);
                  this.props.logout(this.props.session);
                  // clearInterval(this.interVal);
                  // this.props.logout(this.props.session);
                }
              }
            },
          );
        }
      } else {
        this.props.history.push('/login');
      }
    }
  }

  componentDidUpdate(prevState) {
    if (prevState.language !== this.props.language) {
      if (this.props.language === 'en') {
        this.setState({textDiretion: ''});
      } else {
        this.setState({textDiretion: 'rtl'});
      }
    }
  }

  // Logout Button Click
  onLogoutClicked() {
    confirmAlert({
      title: LocaleStrings.button_logout,
      message: `${LocaleStrings.logout_want_to_logout}`,
      buttons: [
        {
          label: LocaleStrings.button_yes,
          onClick: () => {
            clearInterval(this.interVal);
            this.props.logout(this.props.session);
            // this.props.history.push("/login");
          },
        },
        {
          label: LocaleStrings.button_no,
          onClick: () => {},
        },
      ],
    });
  }

  // Upgrade Plan Page Redirection
  _openUpgradPlan(e) {
    this.props.history.push('/dashboard/upgradeplan');
  }
  // Setting Page Redirection
  _openSettings(e) {
    // this.props.history.push("/settings");
    this.props.onsettingsSidebarMenuSelected(1);
    this.props.history.push('/dashboard/settings/myaccount');
  }

  // Dashboard Page Redirection
  _onLogoClicked(e) {
    this.props.onSidebarMenuSelected(1);
    this.props.history.push('/dashboard/main/dashboardmain');
  }

  // Dashboard Page Redirection
  // _onScheduledemoclick(e) {
  //   this.props.history.push("/dashboard/scheduledemo");
  // }

  handleDownloadClick = e => {
    window.open(EXTENSION_LINK, '_blank');
  };

  render() {
    var {user, navigation, appuserData} = this.props;

    let role = '';
    let planid = '';

    var userImageSrc = ``;

    if (appuserData && appuserData.data && appuserData.data.length > 0) {
      userImageSrc = appuserData.data[0].avatar;
      role = appuserData.data[0].role;

      if (
        appuserData.data[0]?.client_by_clientid?.usersubscription_by_clientid &&
        appuserData.data[0]?.client_by_clientid?.usersubscription_by_clientid
          .length > 0 &&
        appuserData.data[0]?.client_by_clientid?.usersubscription_by_clientid[0]
          ?.subscriptionplan_by_planid
      ) {
        let subscriptionplan_by_planid =
          appuserData.data[0]?.client_by_clientid
            ?.usersubscription_by_clientid[0]?.subscriptionplan_by_planid;
        planid = subscriptionplan_by_planid.planid;
      }
    }

    return (
      <div className="h-screen flex overflow-hidden bg-quaternary">
        <div className="flex flex-col w-0 flex-1 overflow-hidden">
          <div className="bg-custWhite shadow border-b border-gray-200">
            <div className="px-10 py-2 lg:px-12 flex">
              <div className="mt-0">
                <img
                  className="w-32 cursor-pointer"
                  onClick={e => this._onLogoClicked(e)}
                  src="../../images/kipplo-logo.webp"></img>
              </div>
              <div className="items-center mt-1 hidden sm:flex ml-auto">
                <div
                  className="group relative inline-flex items-center"
                  onClick={e => this.handleDownloadClick(e)}>
                  <div className="opacity-0 w-40 text-center group-hover:opacity-100 absolute bg-gray-900 text-white text-sm py-1 px-2 rounded-lg right-10">
                    Download Extension
                  </div>
                  <img
                    className="pr-2 w-8 h-6 cursor-pointer"
                    src="../../images/extension.png"
                    alt="Download Extension"
                  />
                </div>
                <div
                  className="items-center px-4 cursor-pointer"
                  // onClick={(e) => this._onScheduledemoclick(e)}
                >
                  <a href="https://calendly.com/app-kipplo" target="_blank">
                    {LocaleStrings.scheduledemo}
                  </a>
                </div>
                {role !== 'client' ? (
                  ''
                ) : (
                  <div className="w-36 items-center mx-4">
                    <button
                      type="button"
                      className="btn-primary  items-center"
                      onClick={e => this._openUpgradPlan(e)}>
                      <img
                        className="pr-2"
                        src="../../images/thunder.png"></img>{' '}
                      {/* {LocaleStrings.viewplan} */}
                      {planid == PROFESSIONAL_YEARLY ? 'View Plans' : 'Upgrade'}
                    </button>
                  </div>
                )}

                <button
                  className="relative bg-custWhite p-1 rounded-full text-ternary hover:text-secondary mx-2"
                  onClick={e => this._openSettings(e)}>
                  <img
                    loading="lazy"
                    className={' h-5 w-5 icon-hover-transition'}
                    src={'../../images/setting.png'}
                  />
                </button>
                <Menu as="div" className="relative mx-2">
                  {({open}) => (
                    <>
                      <div>
                        <Menu.Button className="max-w-xs bg-custWhite flex items-center text-sm rounded-full">
                          <span className="sr-only">Open user menu</span>
                          <img
                            loading="lazy"
                            id="profile-image-2"
                            className="h-9 w-9 rounded-full"
                            src={userImageSrc}
                            alt="Image"
                            onError={e => {
                              e.target.onerror = null;
                              e.target.src = '../../images/user.png';
                            }}
                          />
                        </Menu.Button>
                      </div>
                      <Transition
                        show={open}
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95">
                        <Menu.Items
                          static
                          className="z-50 absolute mt-2 w-48 rounded-md shadow-lg py-1 bg-custWhite ring-1 ring-black ring-opacity-5 focus:outline-none origin-top-right right-0">
                          <Menu.Item key="sign_out">
                            <a
                              onClick={this.onLogoutClicked}
                              className="block px-4 py-2 text-sm text-secondary cursor-pointer">
                              {LocaleStrings.signout}
                            </a>
                          </Menu.Item>
                        </Menu.Items>
                      </Transition>
                    </>
                  )}
                </Menu>
              </div>
              <div className="items-center mt-3  sm:hidden ml-auto">
                <Menu as="div" className="relative mx-2">
                  {({open}) => (
                    <>
                      <div>
                        <Menu.Button className="max-w-xs bg-custWhite flex items-center text-sm rounded-full">
                          <span className="sr-only">
                            Open menu small screen
                          </span>
                          <img
                            loading="lazy"
                            id="profile-image-2"
                            className="h-9 w-9 rounded-full"
                            src={userImageSrc}
                            alt="Image"
                            onError={e => {
                              e.target.onerror = null;
                              e.target.src = '../../images/user.png';
                            }}
                          />
                        </Menu.Button>
                      </div>
                      <Transition
                        show={open}
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95">
                        <Menu.Items
                          static
                          className="z-50 origin-top-right right-0 absolute mt-2 w-48 rounded-md shadow-lg py-1 bg-custWhite ring-1 ring-black ring-opacity-5 focus:outline-none">
                          <Menu.Item key="sign_out">
                            <a
                              onClick={e => this.handleDownloadClick(e)}
                              className="block px-4 py-2 text-sm text-secondary cursor-pointer">
                              Download Extension
                            </a>
                          </Menu.Item>
                          <Menu.Item key="sign_out">
                            <a
                              href="https://calendly.com/app-kipplo"
                              target="_blank"
                              className="block px-4 py-2 text-sm text-secondary cursor-pointer">
                              {LocaleStrings.scheduledemo}
                            </a>
                          </Menu.Item>
                          {role !== 'client' ? (
                            ''
                          ) : (
                            <Menu.Item key="sign_out">
                              <a
                                onClick={e => this._openUpgradPlan(e)}
                                className="block px-4 py-2 text-sm text-secondary cursor-pointer">
                                {LocaleStrings.upgrade}
                              </a>
                            </Menu.Item>
                          )}
                          <Menu.Item key="sign_out">
                            <a
                              onClick={e => this._openSettings(e)}
                              className="block px-4 py-2 text-sm text-secondary cursor-pointer">
                              Settings
                            </a>
                          </Menu.Item>

                          <Menu.Item key="sign_out">
                            <a
                              onClick={this.onLogoutClicked}
                              className="block px-4 py-2 text-sm text-secondary cursor-pointer">
                              {LocaleStrings.signout}
                            </a>
                          </Menu.Item>
                        </Menu.Items>
                      </Transition>
                    </>
                  )}
                </Menu>
              </div>
            </div>
          </div>
          <main className="main-tag-class- bg-white">
            <Switch>
              {/*All routes under /dashboard */}
              {/*Sidebar components has the main tab's Dashbard, My contacts inside it path for it ./index.js*/}
              <Route
                component={Sidebar}
                history={this.props.history}
                path="/dashboard/main"
              />
              <Route
                component={UpgradePlan}
                history={this.props.history}
                path="/dashboard/upgradeplan"
              />
              <Route
                component={Settings}
                history={this.props.history}
                path="/dashboard/settings"
              />
              {/* <Route
                component={ScheduleDemo}
                history={this.props.history}
                path="/dashboard/scheduledemo"
              /> */}
              <Route
                component={PlanSuccess}
                history={this.props.history}
                path="/dashboard/paysuccess"
              />
              <Route
                component={PayFail}
                history={this.props.history}
                path="/dashboard/payfail"
              />
              <Route
                component={CreditAddons}
                history={this.props.history}
                path="/dashboard/creditaddons"
              />
            </Switch>
            <Toaster />
          </main>
          <main className="main-tag-class- ">
            <Switch>
              {/* <Route
                component={ScheduleDemo}
                history={this.props.history}
                path="/dashboard/scheduledemo"
              /> */}
              <Route
                component={PrivacyPolicy}
                history={this.props.history}
                path="/dashboard/privacypolicy"
              />
              <Route
                component={Termsofuse}
                history={this.props.history}
                path="/dashboard/termsofuse"
              />
              <Route
                component={ChromePrivacy}
                history={this.props.history}
                path="/dashboard/chromeprivacy"
              />
            </Switch>
            <Toaster />
          </main>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  var {session, user} = state;
  return {
    session,
    user,
    isLoggedIn: state.isLoggedIn,
    sidebarSelectedMenu: state.sidebarSelectedMenu,
    language: state.language,
    navigation: state.sideBarMenus,
    appuserData: state.appuserData,
  };
}

export default connect(mapStateToProps, {
  onSidebarMenuSelected,
  showNotificatonCount,
  isNotificationModalOpen,
  logout,
  fetchsessionData,
  resetStore,
  onsettingsSidebarMenuSelected,
  fetchAppuser,
  getDeviceSize,
  // selectedProfileSettingsMenu,
})(Main);
