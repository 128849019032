import React, {useEffect, useState} from 'react';
import Like from '../../../../assets/images/like.png';
import Dislike from '../../../../assets/images/dislike.png';
import CopyGray from '../../../../assets/images/copy-gray.png';

import {classNames} from '@commonFunction';
import _ from 'lodash';
import {toast} from 'react-hot-toast';
import AnimatedSVG from './animatedsvg';
import iconvalid from '../../../../assets/images/mycontacts/valid.png';
import iconunknown from '../../../../assets/images/mycontacts/unknown.png';
import {getContact} from '../actions';
import {connect} from 'react-redux';
import {
  appConstant,
  normalizeLinkedinUrl,
  parse,
} from '../../../../common/common-utils';

const noEmail = 'No email found';

function Business(props) {
  const [selectedEmail, setSelectedEmail] = useState('');
  const [showEmailTooltip, setShowEmailTooltip] = useState(false);
  const [fetchBusiness, setFetchBusiness] = useState(false);
  const [showBusinessEmailInfo, setShowBusinessEmailInfo] = useState(false);
  const [selectedBusinessInfoEmail, setSelectedBusinessInfoEmail] =
    useState('');

  const fetchContact = type => {
    appConstant.kFetchedBusinessEmail = false;
    props.getContact(
      props.session,
      {
        linkedinurl: normalizeLinkedinUrl(props.linkedinurl),
        type,
        action: 'reveal',
      },
      response => {
        setFetchBusiness(false);
      },
    );
  };

  return (
    <div
      className={classNames(
        'flex flex-row justify-between',
        props.islast ? '' : 'border-b border-solid border-gray-300',
      )}>
      {props.businessEmails.length && props.showBusiness ? (
        <div className={classNames('mx-5 flex flex-col py-6')}>
          <span className="text-sm font-medium text-gray-700">
            {'Business Email'}
          </span>
          {_.map(props.businessEmails, (item, idx) => {
            return (
              <div className="flex flex-row relative" key={idx}>
                <span
                  className="flex items-center justify-center"
                  onMouseEnter={() => {
                    if (item.address !== noEmail) {
                      setSelectedBusinessInfoEmail(item.address);
                      setShowBusinessEmailInfo(true);
                    }
                  }}
                  onMouseLeave={() => {
                    setSelectedBusinessInfoEmail('');
                    setShowBusinessEmailInfo(false);
                  }}>
                  {item.address !== noEmail && item.type !== 'none' ? (
                    item.bus_email_validation === 'unknown' ? (
                      <img
                        src={iconunknown}
                        className="cursor-pointer"
                        width={24}
                      />
                    ) : item.bus_email_validation === 'valid' ? (
                      <img
                        src={iconvalid}
                        className="cursor-pointer"
                        width={24}
                      />
                    ) : null
                  ) : null}
                  {showBusinessEmailInfo &&
                  selectedBusinessInfoEmail === item.address ? (
                    <div
                      className={classNames(
                        'absolute flex flex-row border-gray-200 rounded-lg border border-solid pt-1 pb-1 pl-3 pr-2 ml-0 back bg-gray-600 z-10 top-6',
                        item.bus_email_validation === 'unknown'
                          ? 'w-44'
                          : 'w-20',
                      )}>
                      {item.bus_email_validation === 'unknown' ? (
                        <div>
                          {/* <img
                                src={textunknown}
                                className="w-full h-auto"
                              /> */}
                          {/* <div
                                className="w-0 h-0 
                                          border-t-[10px] border-t-transparent
                                          border-r-[10px] border-t-gray-600
                                          border-b-[10px] border-b-transparent"></div> */}

                          <div className="flex flex-row relative">
                            {/* <span className="items-center justify-center mr-2">
                                  <img
                                    src={iconunknowninfo}
                                    className="w-6 h-auto"
                                  />
                                </span> */}
                            <div className="flex flex-col">
                              <span className="text-sm text-white -mt-0.5 text-center">
                                Partially Verified (Free)
                              </span>
                              {/* <span className="text-xs font-normal font-inter bg-white p-2 rounded-md text-gray-600 w-fit">
                                    FREE
                                  </span> */}
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div>
                          {/* <img src={textvalid} className="w-full h-auto" /> */}
                          <div className="flex flex-row">
                            {/* <span className="items-center justify-center mr-2">
                                  <img
                                    src={iconvalidinfo}
                                    className="w-6 h-auto"
                                  />
                                </span> */}
                            {/* <div
                                  className="w-0 h-0 
                                            border-t-[10px] border-t-transparent
                                            border-r-[10px] border-t-gray-600
                                            border-b-[10px] border-b-transparent"></div> */}
                            <div className="flex flex-col">
                              <span className="flex text-sm text-white mt-0 text-center">
                                Verified
                              </span>
                              {/* <span className="text-sm font-bold font-inter text-white">
                                    97% average delivery rate.
                                  </span> */}
                            </div>
                          </div>
                          {/* <div>
                              <span className="text-sm font-bold font-inter text-white">
                                97% average delivery rate.
                              </span>
                            </div>
                            <div>
                              <span className="text-xs font-normal text-white leading-tight">
                                Safe to use. This email has been confirmed by
                                the receiving server or sourced from reputable
                                sources, such as the company's official
                                website.
                              </span>
                            </div> */}
                        </div>
                      )}
                    </div>
                  ) : null}
                </span>
                <div
                  key={idx}
                  className="flex items-center justify-between cursor-pointer relative"
                  onMouseEnter={() => {
                    if (item.address !== noEmail) {
                      setSelectedEmail(item.address);
                      setShowEmailTooltip(true);
                    }
                  }}
                  onMouseLeave={() => {
                    setSelectedEmail('');
                    setShowEmailTooltip(false);
                  }}>
                  <span className="text-sm font-normal font-inter text-gray-500">
                    {item.address}
                  </span>
                  {props.business_email_encrypted ? null : showEmailTooltip &&
                    selectedEmail === item.address ? (
                    <div className="cursor-pointer shadow absolute right-0 flex flex-row border-gray-200 rounded-lg border border-solid px-4 py-3 back bg-white">
                      <span
                        onClick={() => {
                          props.copy(item.address);
                          toast.success('Successfully copied!');
                        }}>
                        <img className="h-4 mr-6" src={CopyGray}></img>
                      </span>
                      <span
                        onClick={() => {
                          props.updateContactMeta(
                            item.address,
                            'upvote',
                            'businessemail',
                          );
                        }}>
                        <img className="h-4 mr-6" src={Like}></img>
                      </span>
                      <span
                        onClick={() => {
                          props.updateContactMeta(
                            item.address,
                            'downvote',
                            'businessemail',
                          );
                        }}>
                        <img className="h-4" src={Dislike}></img>
                      </span>
                    </div>
                  ) : null}
                </div>
              </div>
            );
          })}
        </div>
      ) : null}

      {props.businessEmails.length &&
      props.showBusiness &&
      props.business_email_encrypted &&
      !props.expired &&
      !props.nodetails &&
      !appConstant.kFetchedBusinessEmail ? (
        <div className="w-45 right items-center justify-center flex flex-col">
          <div
            className={classNames(
              'mx-2 bg-themeblue justify-center items-center rounded-lg cursor-pointer relative',
              fetchBusiness ? 'opacity-70 w-44' : 'opacity-100',
            )}
            onClick={() => {
              if (props.businesscredits === 0)
                props.history.push('/dashboard/upgradeplan');
              else {
                setFetchBusiness(true);
                fetchContact('businessemail');
              }
            }}>
            <div className="btn-primary">
              {fetchBusiness
                ? 'Please wait'
                : props.businesscredits > 0
                  ? 'Reveal Email*'
                  : 'Upgrade'}
            </div>
            {fetchBusiness ? (
              <div className="absolute right-2 top-2.5">
                <AnimatedSVG />
              </div>
            ) : null}
          </div>
          <div className="flex">
            <span className="text-xs text-gray-400 mt-2">
              {'*1 credit will be deducted'}
            </span>
          </div>
        </div>
      ) : null}
    </div>
  );
}

function mapStateToProps(state) {
  const {searchedData, appuserData, session} = state;

  let noBusinessEmails = true;
  let businessEmails = [];
  let encrypted = false;
  let email_validation = 'unknown';
  if (searchedData) {
    let {business_email_encrypted, bus_email_validation, businessemailcost} =
      searchedData;

    let emails = parse(searchedData.emails, session.sessionToken);

    emails =
      emails && emails !== 'null' ? JSON.parse(emails.replace(/'/g, '"')) : [];

    businessEmails = emails.filter(email => email.type === 'business');

    if (businessEmails.length === 0) {
      if (appConstant.kFetchedBusinessEmail) {
        businessEmails.push({
          address: noEmail,
          type: 'none',
        });
      } else {
        businessEmails.push({
          address: '*****@****.***',
          type: 'none',
        });
      }
    } else {
      noBusinessEmails = false;
      const modifiedEmails = [];
      businessEmails.map(email => {
        if (!email.encrypted) {
          modifiedEmails.push({...email, bus_email_validation});
        } else {
          modifiedEmails.push({...email, bus_email_validation: null});
        }
      });

      businessEmails = modifiedEmails;
    }

    encrypted = business_email_encrypted;
    email_validation = bus_email_validation;
  }

  return {
    searchedData,
    businesscredits: appuserData.credits.businesscredits,
    session,
    noBusinessEmails,
    businessEmails,
    business_email_encrypted: encrypted,
    bus_email_validation: email_validation,
  };
}

export default connect(mapStateToProps, {getContact})(Business);
