import React, {Component} from 'react';
import {connect} from 'react-redux';
import _ from 'lodash';
import moment from 'moment';
import toast, {Toaster} from 'react-hot-toast';
import {STRIPE_PUBLISHABLE_KEY, TRIALPLANID} from '@constant';
import ReactConfirmAlert, {confirmAlert} from 'react-confirm-alert';
import {classNames} from '@commonFunction';
import LocaleStrings from '@language';
import {converDateIntoLocal} from '@commonFunction';
import {
  upgradePlan,
  subscription,
  fetchPlanInvoice,
  updatePlan,
  updateUsersubscription,
} from '@upgradePlanActions';
import {fetchsessionData} from '@mainActions';
import {fetchAppuser} from '@sidebarActions';
import {loadStripe} from '@stripe/stripe-js';
import {
  ENTERPRISE_MONTHLY,
  ENTERPRISE_YEARLY,
} from '../../../../../../common/constants';

let stripe = null;
loadStripe(STRIPE_PUBLISHABLE_KEY).then(val => (stripe = val));

class UpgradeplanItemsSettings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sidebarOpen: false,
      selectMenuNum: 1,
      selectedThreadId: '',
      isBtnDisable: false,
      btnLoader: false,
      currentplanbtnDisable: true,
    };
    // All Binded Functions
  }

  renderList() {
    let {values} = this.props;
    let feature_json = JSON.parse(values.new_features_json);
    const {planid} = values;
    //Exclude API feature all plans except Enterprise
    if (planid !== ENTERPRISE_YEARLY && planid !== ENTERPRISE_MONTHLY) {
      feature_json = feature_json.filter(
        obj => obj.value !== 'apiaccessavailable',
      );
    }

    let item = '';
    if (feature_json.length > 0) {
      item = _.map(feature_json, (val, index) => {
        return (
          <ul role="list" className="mt-8 space-y-4 group relative">
            <li className="flex space-x-3 ">
              <img
                src="../../images/bluecheckicon.png"
                className="h-5 w-5"
                aria-hidden="true"
              />
              <div className="text-sm text-gray-500">{val.name}</div>
              {val.tooltip && val.tooltip != '' ? (
                <img
                  className="cursor-pointer w-4 h-4"
                  src="../../images/infoicon.png"></img>
              ) : (
                ''
              )}
            </li>
            {val.tooltip && val.tooltip != '' ? (
              <div
                className={classNames(
                  val.tooltipapi && val.tooltipapi == '1'
                    ? '2xl:left-32 left-24'
                    : '2xl:left-20 left-0',
                  'group-hover:opacity-100 transition-opacity bg-primary text-white rounded  px-1   absolute  -top-12 -translate-x-1/2 translate-y-full opacity-0 mx-auto text-xs',
                )}>
                {/* {val.tooltip} */}
                {val.tooltipapi && val.tooltipapi == '1' ? (
                  <> {val.tooltip}</>
                ) : (
                  <a
                    href="https://www.kipplo.com/terms-and-conditions/"
                    target="_blank">
                    {val.tooltip}
                  </a>
                )}
              </div>
            ) : (
              ''
            )}
          </ul>
        );
      });
    }
    return item;
  }

  _handleredirection = (type, exceededby) => {
    let {session, user} = this.props;
    let title = '';
    let description = '';
    let pushurl = '';
    if (type == 'api') {
      title = 'API Limit Exceeded';
      description = `Please delete ${exceededby} API ,as your selected plan has less number of API access`;
      pushurl = '/dashboard/settings/api';
    } else {
      title = 'Member Limit Exceeded';
      description = `Please delete ${exceededby} member/admin to downgrade,as your selected plan has less number of member/admin access`;
      pushurl = '/dashboard/settings/manageseats';
    }
    confirmAlert({
      title: title,
      message: description,
      buttons: [
        {
          label: `Delete ${type}`,
          onClick: () => {
            this.props.history.push(pushurl);
          },
        },
        {
          label: 'Cancel',
          onClick: () => {},
        },
      ],
    });
  };

  upgradePlan(e, values, subId) {
    let {user, session, memberadminListcount, apikeysListcount, appuserData} =
      this.props;

    let planState = 'initial';
    let currentSku = null;
    let usercurrentplanid = null;
    let autorenew = 1;
    let current_stripe_custid = null;
    let expiry_date = null;
    let clientid = null;
    let subscriptionid = null;
    // APPUSER DATA
    const otherPlan = ['custom_trial', 'custom_monthly', 'custom_yearly', null];
    if (
      appuserData &&
      appuserData.data &&
      appuserData.data.length > 0 &&
      appuserData.data[0].client_by_clientid
    ) {
      let client_by_clientid = appuserData.data[0]?.client_by_clientid;
      current_stripe_custid =
        client_by_clientid.stripe_custid != null ||
        client_by_clientid.stripe_custid != ''
          ? client_by_clientid.stripe_custid
          : null;
      clientid = client_by_clientid.clientid;
      if (
        appuserData.data[0]?.client_by_clientid?.usersubscription_by_clientid &&
        appuserData.data[0]?.client_by_clientid?.usersubscription_by_clientid
          .length > 0
      ) {
        let usersubscription_by_clientid =
          appuserData.data[0]?.client_by_clientid
            ?.usersubscription_by_clientid[0];
        usercurrentplanid = usersubscription_by_clientid.planid;
        currentSku = usersubscription_by_clientid.sku;
        autorenew = usersubscription_by_clientid.autorenew;
        expiry_date = usersubscription_by_clientid.expirydate;
        subscriptionid = usersubscription_by_clientid.subscriptionid;
      }
    }
    if (autorenew == 0 || autorenew == '0') {
      planState = 'initial';
    } else {
      if (!otherPlan.includes(values.sku) && !otherPlan.includes(currentSku)) {
        if (values.planid > usercurrentplanid) {
          planState = 'up';
        } else {
          planState = 'down';
        }
      }
    }

    if (values.price > 0) {
      //This is for Stripe Subscription
      if (planState == 'initial') {
        if (autorenew == 0 || autorenew == '0') {
          let availableseats = values.availableseats;
          let availableapicall = values.availableapicall;

          let memberExccedby = 0;
          let apiExccedby = 0;

          let memberError = 0;
          let apiError = 0;

          let currentmemberListcount = '';
          let currentapilistcount = '';
          if (memberadminListcount.data) {
            currentmemberListcount = memberadminListcount.data;
          }
          if (apikeysListcount.data) {
            currentapilistcount = apikeysListcount.data;
          }

          if (parseInt(currentmemberListcount) > parseInt(availableseats)) {
            memberExccedby =
              parseInt(currentmemberListcount) - parseInt(availableseats);
            memberError = 1;
          }

          if (parseInt(currentapilistcount) > parseInt(availableapicall)) {
            apiExccedby =
              parseInt(currentapilistcount) - parseInt(availableapicall);
            apiError = 1;
          }

          if (memberError == 1) {
            this.setState({btnLoader: false});
            this.setState({isBtnDisable: false});
            this._handleredirection('member', memberExccedby);
          } else if (apiError == 1) {
            this.setState({btnLoader: false});
            this.setState({isBtnDisable: false});
            this._handleredirection('api', apiExccedby);
          } else {
            var data = {
              email: user.email,
              plan: values.planid,
              ...(current_stripe_custid ? {custId: current_stripe_custid} : {}),
            };
            this.setState({btnLoader: true});
            this.setState({isBtnDisable: true});

            this.props.subscription(session, data, response => {
              this.setState({btnLoader: false});
              this.setState({isBtnDisable: false});
              stripe.redirectToCheckout({sessionId: response.resource.data});
            });
          }
        } else {
          var data = {
            email: user.email,
            plan: values.planid,
            ...(current_stripe_custid ? {custId: current_stripe_custid} : {}),
          };
          this.setState({btnLoader: true});
          this.setState({isBtnDisable: true});

          this.props.subscription(session, data, response => {
            this.setState({btnLoader: false});
            this.setState({isBtnDisable: false});
            stripe.redirectToCheckout({sessionId: response.resource.data});
          });
        }
      } else if (planState == 'up' && subId) {
        var data = `subId=${subId}&plan=${values.planid}&res=invoice`;
        this.setState({btnLoader: true});
        this.setState({isBtnDisable: true});

        let availableseats = values.availableseats;
        let availableapicall = values.availableapicall;

        let memberExccedby = 0;
        let apiExccedby = 0;

        let memberError = 0;
        let apiError = 0;

        let currentmemberListcount = '';
        let currentapilistcount = '';
        if (memberadminListcount.data) {
          currentmemberListcount = memberadminListcount.data;
        }
        if (apikeysListcount.data) {
          currentapilistcount = apikeysListcount.data;
        }

        if (parseInt(currentmemberListcount) > parseInt(availableseats)) {
          memberExccedby =
            parseInt(currentmemberListcount) - parseInt(availableseats);
          memberError = 1;
        }

        if (parseInt(currentapilistcount) > parseInt(availableapicall)) {
          apiExccedby =
            parseInt(currentapilistcount) - parseInt(availableapicall);
          apiError = 1;
        }

        if (memberError == 1) {
          this.setState({btnLoader: false});
          this.setState({isBtnDisable: false});
          this._handleredirection('member', memberExccedby);
        } else if (apiError == 1) {
          this.setState({btnLoader: false});
          this.setState({isBtnDisable: false});
          this._handleredirection('api', apiExccedby);
        } else {
          this.props.fetchPlanInvoice(session, data, response => {
            if (
              response.resource &&
              response.resource.status &&
              response.resource.status == true &&
              response.resource.code &&
              response.resource.code == 200 &&
              response.resource.data &&
              response.resource.data.subscription_proration_date
            ) {
              let proration_date =
                response.resource.data.subscription_proration_date;

              let subtotal = response.resource.data.subtotal;
              let total = response.resource.data.total;

              var data = {
                res: 'sub',
                plan: values.planid,
                subId: subId,
              };

              let sessionobj = {};
              sessionobj.session_token = session.sessionToken;

              this.props.updatePlan(session, data, feedback => {
                //console.log("feedback", feedback);
                // page load and show   toast.success("Plan updated successfully");

                this.props.fetchsessionData(sessionobj, '', response => {
                  if (response.success === 0) {
                    toast.error(LocaleStrings.common_fail_message);
                  } else {
                    this.props.fetchAppuser(
                      session,
                      user.appuserid,
                      callback => {},
                    );
                    this.setState({btnLoader: false});
                    this.setState({isBtnDisable: false});
                    toast.success(
                      LocaleStrings.upgradeplan_upgraded_plansuccess,
                    );
                  }
                });
              });
            } else {
              //console.log("response else", response);
              this.setState({btnLoader: false});
              this.setState({isBtnDisable: false});
            }
          });
        }
      } else if (planState == 'down' && subId) {
        var data = `subId=${subId}&plan=${values.planid}&res=invoice&stdate=yes`;
        this.setState({btnLoader: true});
        this.setState({isBtnDisable: true});
        let availableseats = values.availableseats;
        let availableapicall = values.availableapicall;

        let memberExccedby = 0;
        let apiExccedby = 0;

        let memberError = 0;
        let apiError = 0;

        let currentmemberListcount = '';
        let currentapilistcount = '';
        if (memberadminListcount.data) {
          currentmemberListcount = memberadminListcount.data;
        }
        if (apikeysListcount.data) {
          currentapilistcount = apikeysListcount.data;
        }

        if (parseInt(currentmemberListcount) > parseInt(availableseats)) {
          memberExccedby =
            parseInt(currentmemberListcount) - parseInt(availableseats);
          memberError = 1;
        }

        if (parseInt(currentapilistcount) > parseInt(availableapicall)) {
          apiExccedby =
            parseInt(currentapilistcount) - parseInt(availableapicall);
          apiError = 1;
        }

        if (memberError == 1) {
          this.setState({btnLoader: false});
          this.setState({isBtnDisable: false});
          this._handleredirection('member', memberExccedby);
        } else if (apiError == 1) {
          this.setState({btnLoader: false});
          this.setState({isBtnDisable: false});
          this._handleredirection('api', apiExccedby);
        } else {
          this.props.fetchPlanInvoice(session, data, response => {
            if (
              response.resource &&
              response.resource.status &&
              response.resource.status == true &&
              response.resource.code &&
              response.resource.code == 200 &&
              response.resource.data &&
              response.resource.data.subscription_proration_date
            ) {
              let proration_date =
                response.resource.data.subscription_proration_date;

              let subtotal = response.resource.data.subtotal;
              let total = response.resource.data.total;

              if (expiry_date != null) {
                let updateobj = {
                  subscriptionid: subscriptionid,
                  clientid: clientid,
                  downgrade_startson: expiry_date,
                };

                this.props.updateUsersubscription(
                  session,
                  updateobj,
                  callbackres => {
                    if (callbackres.success && callbackres.success == 1) {
                      var data = {
                        res: 'sub',
                        plan: values.planid,
                        subId: subId,
                        proration_date: proration_date,
                      };

                      this.props.updatePlan(session, data, feedback => {
                        toast.success(
                          LocaleStrings.upgradeplan_downgrade_success,
                        );
                        this.setState({btnLoader: false});
                        this.setState({isBtnDisable: false});
                      });
                    }
                  },
                );
              }
            } else {
              this.setState({btnLoader: false});
              this.setState({isBtnDisable: false});
            }
          });
        }
      }
    } else {
      if (values.sku == 'custom_yearly' || values.sku == 'custom_monthly') {
        // this is for enterprise plan
        this.props.history.push('/dashboard/scheduledemo');
        // toast.error("Enterprise Plan is under development");
      } else {
        // This is for free subscription
        let obj = {};
        let sessionobj = {};
        sessionobj.session_token = session.sessionToken;
        obj.planid = values.planid;
        obj.clientid = user.clientid;
        obj.subscriptionid = user?.usersubscription_by_clientid[0]
          ?.subscriptionid
          ? user?.usersubscription_by_clientid[0]?.subscriptionid
          : null;

        this.setState({btnLoader: true});
        this.setState({isBtnDisable: true});
        this.props.upgradePlan(session, obj, callback => {
          if (callback.success == 1) {
            this.props.fetchAppuser(session, user.appuserid, callback => {});
            this.props.fetchsessionData(sessionobj, '', response => {
              if (response.success === 0) {
                toast.error(LocaleStrings.common_fail_message);
              } else {
                this.setState({btnLoader: false});
                this.setState({isBtnDisable: false});
                toast.success(LocaleStrings.upgradeplan_upgraded_success);
              }
            });
          } else {
            toast.error(LocaleStrings.common_fail_message);
          }
        });
      }
    }
  }

  _handleaddonRedirect(e, values, subId, planState) {
    let {user, session} = this.props;
    this.props.history.push('/dashboard/creditaddons');
  }

  render() {
    let {user, values, appuserData} = this.props;
    let usercurrentplanid = 0;
    let buttonname = LocaleStrings.upgradeplan_item_btn_subscribenow;
    let expirydate = '';
    let contactcredit = '';
    let subId = null;
    let currentSku = null;
    let autorenew = null;
    const otherPlan = ['custom_trial', 'custom_monthly', 'custom_yearly'];
    // if (user && user.usersubscription_by_clientid && user.usersubscription_by_clientid.length > 0) {
    //     usercurrentplanid = user.usersubscription_by_clientid[0]?.subscriptionplan_by_planid?.planid;
    // }

    if (
      appuserData &&
      appuserData.data &&
      appuserData.data.length > 0 &&
      appuserData.data[0].client_by_clientid
    ) {
      let client_by_clientid = appuserData.data[0]?.client_by_clientid;

      if (
        appuserData.data[0]?.client_by_clientid?.usersubscription_by_clientid &&
        appuserData.data[0]?.client_by_clientid?.usersubscription_by_clientid
          .length > 0
      ) {
        let usersubscription_by_clientid =
          appuserData.data[0]?.client_by_clientid
            ?.usersubscription_by_clientid[0];
        expirydate = converDateIntoLocal(
          usersubscription_by_clientid.expirydate,
        ).format('D MMM YYYY');
        usercurrentplanid = usersubscription_by_clientid.planid;
        subId = usersubscription_by_clientid.transactionid;
        currentSku = usersubscription_by_clientid.sku;
        autorenew = usersubscription_by_clientid.autorenew;
      }

      if (
        appuserData.data[0]?.client_by_clientid?.usercredit_by_clientid &&
        appuserData.data[0]?.client_by_clientid?.usercredit_by_clientid.length >
          0
      ) {
        let usercredit_by_clientid =
          appuserData.data[0]?.client_by_clientid?.usercredit_by_clientid[0];
        contactcredit = usercredit_by_clientid.contactcredit;
      }
    }
    let currentDate = moment();
    let expired = false;
    if (expirydate !== '') {
      if (currentDate.isAfter(moment(expirydate))) {
        expired = true;
      }
    }
    let planState = 'initial';
    if (appuserData && appuserData.data && appuserData.data.length > 0) {
      if (expired != true) {
        if (values.planid > usercurrentplanid) {
          buttonname = LocaleStrings.upgradeplan_item_btn_upgrade;
        } else {
          buttonname = LocaleStrings.upgradeplan_item_btn_downgrade;
        }

        if (autorenew == 0 || autorenew == 0) {
          buttonname = 'Subscribe Now';
        }

        if (values.sku == 'custom_yearly' || values.sku == 'custom_monthly') {
          buttonname = LocaleStrings.upgradeplan_item_btn_contactsales;
        }

        if (
          !otherPlan.includes(values.sku) &&
          !otherPlan.includes(currentSku)
        ) {
          if (values.planid > usercurrentplanid) {
            planState = 'up';
          } else {
            planState = 'down';
          }
        }
      }
    }

    return (
      <>
        <div
          className={classNames(
            usercurrentplanid == values.planid ? 'bg-primaryHover' : '',
            'divide-y divide-gray-200 rounded-lg border border-gray-200 shadow-sm transition-all duration-500 transform hover:-translate-y-4 hover:scale-105',
          )}>
          <div className="p-6">
            <h2 className="text-lg font-medium leading-6 text-gray-900">
              {values.name}
            </h2>
            {values.price == 0 ? (
              <div className="flex my-6">
                <div className="text-3xl">
                  {LocaleStrings.upgradeplan_item_btn_initialplan_price}
                </div>
              </div>
            ) : values.price == -1 ? (
              <div className="flex my-9">
                <img clasName="" src="../../images/infinity.png" />
              </div>
            ) : (
              <div className="flex my-6">
                <div className="text-3xl">${values.price}</div>
                <div className="text-sm ml-1 mt-3 text-gray500">
                  {values.validity == 30
                    ? LocaleStrings.upgradeplan_item_permonth
                    : LocaleStrings.upgradeplan_item_peryear}
                </div>
              </div>
            )}
            {/* <div className="flex my-6">
                            <div className="text-3xl">${values.price}</div>
                            <div className="text-sm ml-1 mt-3 text-gray500">per month</div>
                        </div> */}
            <div className="text-sm  text-gray500">{values.details}</div>
            {usercurrentplanid == values.planid && expired == false ? (
              <button
                type="button"
                className={classNames(
                  this.state.currentplanbtnDisable
                    ? 'cursor-not-allowed'
                    : 'cursor-pointer',
                  'btn-white mt-6',
                )}
                // className="btn-white mt-6"
              >
                {LocaleStrings.upgradeplan_item_btn_currentplan}
              </button>
            ) : values.planid != TRIALPLANID ? (
              <>
                {buttonname == 'Contact Sales' ? (
                  <button type="button" className="btn-primary mt-6">
                    <a href="https://calendly.com/app-kipplo" target="_blank">
                      {buttonname}
                    </a>
                  </button>
                ) : (
                  <button
                    type="button"
                    onClick={e => this.upgradePlan(e, values, subId)}
                    disabled={this.state.isBtnDisable}
                    className={classNames(
                      this.state.isBtnDisable ? 'cursor-not-allowed' : '',
                      'btn-primary mt-6',
                    )}>
                    <svg
                      className={classNames(
                        this.state.btnLoader ? '' : 'hidden',
                        'animate-spin-medium h-5 w-5 rounded-full mx-2',
                      )}
                      disabled={this.state.isBtnDisable}
                      viewBox="0 0 1024 1024"
                      focusable="false"
                      data-icon="loading"
                      width="1em"
                      height="1em"
                      fill="currentColor"
                      aria-hidden="true">
                      <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                    </svg>
                    {buttonname}
                  </button>
                )}
              </>
            ) : (
              ''
            )}

            {usercurrentplanid == values.planid &&
            usercurrentplanid != TRIALPLANID &&
            // contactcredit == 0 &&
            expired == false ? (
              <button
                type="button"
                className="btn-primary mt-6 cursor-pointer"
                onClick={e =>
                  this._handleaddonRedirect(e, values, subId, planState)
                }>
                {LocaleStrings.upgradeplan_item_btn_addon}
              </button>
            ) : (
              ''
            )}
          </div>
          <div className="px-6 pt-6 pb-8">
            <h3 className="text-sm font-medium text-gray-900">
              {LocaleStrings.upgradeplan_item_features}
            </h3>
            {this.renderList()}
          </div>
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  var {session, user} = state;
  return {
    session,
    user,
    subscriptionplanList: state.subscriptionplanList,
    appuserData: state.appuserData,
    memberadminListcount: state.memberadminListcount,
    apikeysListcount: state.apikeysListcount,
  };
}
export default connect(mapStateToProps, {
  upgradePlan,
  fetchsessionData,
  fetchAppuser,
  subscription,
  fetchPlanInvoice,
  updatePlan,
  updateUsersubscription,
})(UpgradeplanItemsSettings);
