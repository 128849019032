import React from "react";
import { connect } from "react-redux";
import { Field, reduxForm, getFormValues } from "redux-form";
import LocaleStrings from "@language";
import _ from "lodash";
import toast from "react-hot-toast";
import { classNames } from "@commonFunction";
import { Fragment } from "react";
import { Transition, Dialog } from "@headlessui/react";
import { BaseComponent, LABEL_POSITION_TOP } from "@baseComponent";
import { fetchsessionData, sendVerificationemail } from "@mainActions";
import {
  fetchmyaccountData,
  updateuserEmail,
  updateEmailModal,
  changeEmailwithcode,
  verifyemailCode,
} from "@settingsMyaccountActions";

const freeEmailDomains = require("free-email-domains");

class UpdateUserEmail extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = {
      isBtnDisable: false,
      btnLoader: false,
      isverified: false,
      iscodeverified: false,
      changeemailbtnLoader: false,
      ischangeemailBtnDisable: false,
    };
    this.closeModal = this.closeModal.bind(this);
  }

  componentDidMount() {}

  closeModal() {
    this.setState({ isverified: false });
    this.props.reset();
    this.props.updateEmailModal(false);
  }

  /***** Form Submit *****/
  onSubmitForm(values) {
    let { initialValues, user, session, history } = this.props;
    let appuserid = user?.appuserid;

    if (!initialValues.newemail || initialValues.newemail == "") {
      toast.error("Invalid Email or Code");
      return false;
    }

    if (values.code == "" || values.code == null) {
      toast.error("Verification Code Missing!");
      return false;
    }

    // if (initialValues.email !== values.email.toLowerCase()) {
    let updatemailvalues = {};
    updatemailvalues.type = values.role;
    updatemailvalues.newemail = initialValues.newemail;
    updatemailvalues.afid = values.afid;

    let verifycodevalues = {};
    verifycodevalues.code = values.code;
    verifycodevalues.email = initialValues.email;

    this.setState({ btnLoader: true });
    this.setState({ isBtnDisable: true });
    // console.log("verifycodevalues", verifycodevalues);
    this.props.verifyemailCode(
      session,
      verifycodevalues,
      (callbackresponse) => {
        // console.log("callbackresponse", callbackresponse);
        if (callbackresponse.success == 0) {
          this.setState({ btnLoader: false });
          this.setState({ isBtnDisable: false });
          toast.error(LocaleStrings.something_went_wrong);
        } else {
          if (
            callbackresponse.data &&
            callbackresponse.data.resource &&
            callbackresponse.data.resource.length > 0
          ) {
            this.props.updateuserEmail(
              session,
              updatemailvalues,
              (callback) => {
                if (callback.success == 0) {
                  this.setState({ btnLoader: false });
                  this.setState({ isBtnDisable: false });
                  toast.error(
                    LocaleStrings.settings_myaacounts_email_update_form_updatefail
                  );
                } else if (callback.success == 2) {
                  this.setState({ btnLoader: false });
                  this.setState({ isBtnDisable: false });
                  toast.error(
                    LocaleStrings.settings_myaacounts_email_update_form_emailexist
                  );
                } else if (callback.success == 1) {
                  let sessionobj = {};
                  sessionobj.session_token = callback.result.session_token;

                  this.props.fetchsessionData(sessionobj, "", (response) => {
                    if (response.success === 0) {
                      toast.error(LocaleStrings.common_fail_message);
                    } else {
                      this.props.fetchmyaccountData(
                        session,
                        callback.result.session_token,
                        appuserid,
                        (callback) => {
                          this.setState({ btnLoader: false });
                          this.setState({ isBtnDisable: false });
                          this.closeModal();
                          this.props.history.push(
                            "/dashboard/settings/myaccount"
                          );
                          toast.success(
                            LocaleStrings.settings_myaacounts_email_verified_n_updatedsuccess
                          );
                        }
                      );
                    }
                  });
                }
              }
            );
          } else {
            this.setState({ btnLoader: false });
            this.setState({ isBtnDisable: false });
            toast.error(
              LocaleStrings.settings_myaacounts_verificationcode_mismatched
            );
          }
        }
      }
    );
    // } else {
    //   toast.error(
    //     LocaleStrings.settings_myaacounts_email_update_form_similarmail
    //   );
    // }
  }

  _changeEmail = (e) => {
    let { initialValues, user, session, history, formValues } = this.props;
    let appuserid = user?.appuserid;
    let values = formValues;

    if (initialValues.email !== values.email.toLowerCase()) {
      let isrestrictedEmail = false;
      let emailExtention = "";
      if (values.hasOwnProperty("email")) {
        values.email = values.email.toLowerCase();
      }
      if (values.email) {
        emailExtention = values.email.split("@")[1];
        isrestrictedEmail = freeEmailDomains.includes(emailExtention);
      }
      if (isrestrictedEmail) {
        toast.error(LocaleStrings.business_email_error);
        return false;
      }
      let updatemailvalues = {};
      updatemailvalues.oldemail = initialValues.email;
      updatemailvalues.newemail = values.email;
      this.setState({ changeemailbtnLoader: true });
      this.setState({ ischangeemailBtnDisable: true });

      this.props.changeEmailwithcode(session, updatemailvalues, (callback) => {
        if (callback.success == 0) {
          this.setState({ changeemailbtnLoader: false });
          this.setState({ ischangeemailBtnDisable: false });
          toast.error(
            LocaleStrings.settings_myaacounts_email_update_form_updatefail
          );
        } else if (callback.success == 2) {
          this.setState({ changeemailbtnLoader: false });
          this.setState({ ischangeemailBtnDisable: false });
          toast.error(
            LocaleStrings.settings_myaacounts_email_update_form_emailexist
          );
        } else if (callback.success == 1) {
          this.setState({ changeemailbtnLoader: false });
          this.setState({ ischangeemailBtnDisable: false });
          // this.setState({ isverified: true });
          toast.success(`Please click on the link sent on email and update`);
          this.closeModal();
        }
      });
    } else {
      toast.error(
        LocaleStrings.settings_myaacounts_email_update_form_similarmail
      );
    }
  };

  render() {
    let { handleSubmit, initialValues } = this.props;
    let { isverified, iscodeverified } = this.state;

    return (
      <>
        <Transition.Root show={this.props.iseditemailmodalOpen} as={Fragment}>
          <Dialog
            as="div"
            static
            className="fixed z-10 inset-0 overflow-y-auto"
            open={this.props.iseditemailmodalOpen}
            onClose={this.closeModal}
            initialFocus={this.myRef}>
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0">
                <Dialog.Overlay className="fixed inset-0 bg-ternary bg-opacity-75 transition-opacity" />
              </Transition.Child>
              <span
                className="hidden sm:inline-block sm:align-middle sm:h-screen"
                aria-hidden="true">
                &#8203;
              </span>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                <div
                  className="inline-block rounded-xl align-bottom bg-custWhite rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-3/12 "
                  style={{ padding: 0 }}>
                  <form
                    className=" "
                    onSubmit={handleSubmit(this.onSubmitForm.bind(this))}>
                    {/* MODAL HEADER ENDS */}
                    <div className="p-6 border-t-2 border-quaternary">
                      <div className="py-6">
                        <div className="text-lg text-primary mb-4 text-center">
                          {LocaleStrings.settings_myaacounts_email_update_form}
                        </div>
                        {initialValues?.code == "" ? (
                          <div className="mt-4">
                            <Field
                              name="email"
                              label={
                                isverified || initialValues?.code != ""
                                  ? LocaleStrings.settings_myaacounts_field_businessemail
                                  : "Business Email(Please enter email you wish to update)"
                              }
                              placeholder={
                                LocaleStrings.settings_myaacounts_field_businessemail
                              }
                              type="text"
                              isdisabled={
                                isverified || initialValues?.code != ""
                                  ? "true"
                                  : ""
                              }
                              component={this.renderFieldText}
                              mandatory="true"
                              labelposition={LABEL_POSITION_TOP}
                              classNameLabel="text-sm font-normal text-secondary"
                            />
                          </div>
                        ) : (
                          ""
                        )}

                        {isverified || initialValues?.code != "" ? (
                          <div className="mt-4">
                            <Field
                              name="code"
                              label={"Verification Code"}
                              isdisabled={true}
                              placeholder={"Enter Verification Code"}
                              type="text"
                              component={this.renderFieldText}
                              mandatory="true"
                              labelposition={LABEL_POSITION_TOP}
                              classNameLabel="text-sm font-normal text-secondary"
                            />
                          </div>
                        ) : (
                          ""
                        )}
                      </div>

                      <div className="flex">
                        <button
                          type="button"
                          onClick={this.closeModal}
                          className="btn-white text-primary cursor-pointer">
                          {LocaleStrings.cancel}
                        </button>
                        {!isverified && initialValues?.code == "" ? (
                          <button
                            type="button"
                            onClick={(e) => this._changeEmail(e)}
                            className={classNames(
                              this.state.ischangeemailBtnDisable
                                ? "cursor-not-allowed opacity-80"
                                : "",
                              "btn-primary ml-3"
                            )}>
                            <svg
                              className={classNames(
                                this.state.changeemailbtnLoader ? "" : "hidden",
                                "animate-spin-medium h-5 w-5 rounded-full mx-2"
                              )}
                              disabled={this.state.isBtnDisable}
                              viewBox="0 0 1024 1024"
                              focusable="false"
                              data-icon="loading"
                              width="1em"
                              height="1em"
                              fill="currentColor"
                              aria-hidden="true">
                              <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                            </svg>
                            {LocaleStrings.settings_myaacounts_change_emailbtn}
                          </button>
                        ) : (
                          ""
                        )}

                        {/* Footer */}
                        {isverified || initialValues?.code != "" ? (
                          <button
                            type="submit"
                            disabled={this.state.isBtnDisable}
                            className={classNames(
                              this.state.isBtnDisable
                                ? "cursor-not-allowed opacity-80"
                                : "",
                              "btn-primary ml-3"
                            )}>
                            <svg
                              className={classNames(
                                this.state.btnLoader ? "" : "hidden",
                                "animate-spin-medium h-5 w-5 rounded-full mx-2"
                              )}
                              disabled={this.state.isBtnDisable}
                              viewBox="0 0 1024 1024"
                              focusable="false"
                              data-icon="loading"
                              width="1em"
                              height="1em"
                              fill="currentColor"
                              aria-hidden="true">
                              <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                            </svg>
                            {
                              LocaleStrings.settings_myaacounts_verify_and_update_btn
                            }
                          </button>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </form>
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>
      </>
    );
  }
}

function validate(values) {
  var errors = {};
  var email = values["email"];
  var requiredFields = ["email"];

  requiredFields.forEach((field) => {
    if (!values[field] || _.trim(values[field]) === "") {
      errors[field] = LocaleStrings.required;
    }
  });
  return errors;
}

function mapStateToProps(state) {
  var { session, user } = state;

  let verifiedcode = "";
  let newemail = "";
  let initval = {};
  console.log("state.iseditemailcode", state.iseditemailcode);
  if (state.iseditemailcode && Object.keys(state.iseditemailcode).length > 0) {
    verifiedcode = state.iseditemailcode.code;
    newemail = state.iseditemailcode.email;
  }

  initval = state.myaccountdataList?.data;
  if (initval != undefined) {
    if (verifiedcode != "") {
      initval.code = verifiedcode;
      initval.newemail = newemail;
    } else {
      initval.code = "";
      initval.newemail = "";
    }
  }

  console.log("verifiedcode", verifiedcode);

  return {
    session,
    user,
    isLoggedIn: state.isLoggedIn,
    language: state.language,
    iseditemailmodalOpen: state.iseditemailmodalOpen,
    initialValues: initval,
    formValues: getFormValues("MyaccountsForm1")(state),
  };
}

export default connect(mapStateToProps, {
  updateEmailModal,
  fetchmyaccountData,
  updateuserEmail,
  fetchsessionData,
  sendVerificationemail,
  changeEmailwithcode,
  verifyemailCode,
})(
  reduxForm({
    validate,
    form: "MyaccountsForm1",
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
  })(UpdateUserEmail)
);
