import React, {Component} from 'react';
import {connect} from 'react-redux';
import _ from 'lodash';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import {Switch, Route} from 'react-router-dom';
// Import Action

import {
  showNotificatonCount,
  isNotificationModalOpen,
  onSidebarMenuSelected,
} from '@sidebarActions';
import {isEmpty, classNames} from '@commonFunction';
// Import pages
import DashboardMain from '@maindashBoard';
import MainSearch from '@mainSearch';
import Mycontacts from '@myContacts';
import toast, {Toaster} from 'react-hot-toast';
import '../sidebar.css';

class SideBar extends Component {
  constructor(props) {
    super(props);
  }

  // Component Did mount
  componentDidMount() {
    let {session} = this.props;
    //splitting pathname to updated the selected menu
    var parts = this.props.history.location.pathname.substring(16).split('/');
    var answer = parts[0];
    var singleNavigationRow = _.find(this.props.navigation, {href: answer});
    this.props.onSidebarMenuSelected(singleNavigationRow.position);
  }

  // Menu Select (Route change)
  onMenuSelect = item => {
    this.props.onSidebarMenuSelected(item.position);
    this.props.history.push('/dashboard/main/' + item.href);
  };

  render() {
    var {navigation, deviceSize} = this.props;
    return (
      <div className="h-screen flex overflow-hidden bg-quaternary">
        <div className="flex flex-col w-0 flex-1 overflow-hidden">
          <div className="flex-shrink-0 flex h-16 bg-custWhite shadow border-b border-gray-200">
            <div className="flex-1 px-4 md:px-12 py-0.5 flex justify-between">
              <div className="flex-1 flex">
                <nav className="flex lg:space-x-8 lg:py-2 " aria-label="Global">
                  {navigation.map(item => (
                    <span
                      key={item.name}
                      onClick={e => this.onMenuSelect(item)}
                      className={classNames(
                        this.props.sidebarSelectedMenu === item.position
                          ? 'bg-primaryHover text-primary'
                          : 'text-semiGray',
                        'group h-10 flex items-center px-2 py-2 text-base rounded-md cursor-pointer px-4',
                      )}
                      aria-current={item.current ? 'page' : undefined}>
                      {item.name}
                    </span>
                  ))}
                </nav>
              </div>
            </div>
          </div>
          <main
            className="scrollbar-hide xs:px-2 sm:px-12 lg:px-12 py-4 overflow-y-auto bg-homegray"
            style={{height: deviceSize.height - 175}}>
            {/* Routes under /dashboard/main */}
            {/* Note Search is not visible now it is for phase 2*/}
            <Switch>
              <Route
                component={DashboardMain}
                history={this.props.history}
                path="/dashboard/main/dashboardmain"
              />
              <Route
                component={MainSearch}
                history={this.props.history}
                path="/dashboard/main/search"
              />
              <Route
                component={Mycontacts}
                history={this.props.history}
                path="/dashboard/main/mycontacts"
              />
            </Switch>

            <Toaster />
          </main>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  var {session, user} = state;
  return {
    session,
    user,
    isLoggedIn: state.isLoggedIn,
    sidebarSelectedMenu: state.sidebarSelectedMenu,
    language: state.language,
    navigation: state.sideBarMenus,
    deviceSize: state.deviceSize,
  };
}

export default connect(mapStateToProps, {
  onSidebarMenuSelected,
  showNotificatonCount,
  isNotificationModalOpen,
})(SideBar);
